<template>
    <b-modal id="newAction" v-b-modal.modal-multi-1 size="xl" @cancel="onCancel" @ok="onOk" @show="onShow"
        :title="$t('actions.wizard.title')" :cancel-title="$t('Cancel')" :ok-title="$t('Ok')">
        <b-form class="prevent-select">
            <b-row>
                <b-col>
                    <b-form-group :label="$t('actions.wizard.actionName') + ' *'" label-for="newActionName">
                        <b-form-input id="newActionName" v-model="actionName" />
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group :label="$t('actions.wizard.actionType') + ' *'" label-for="newActionType"
                        v-if="action != null">
                        <b-form-select id="newActionType" v-model="action.ActionType" :options="availableActions"
                            @change="onChange" />
                    </b-form-group>
                </b-col>
                <b-col-1 v-on:click="displayHelp" :title="$t('Help')">
                    <font-awesome-icon icon="fas fa-square-question" class="top-menu-icon mr-1 fa-lg" />
                </b-col-1>
            </b-row>
            <Action v-if="shown" :action="action" ref="actionDisplay" :propProject="project" />
        </b-form>
    </b-modal>
</template>

<script>

import Action from "@/components/configuration/Actions/Action.vue";
import ProdComActionService from "@/services/prodcom.actions.service.js";
import ToastAlert from '@/utils/ToastAlert';

export default {
    name: "ActionWizard",
    props: {
        propEquipment: String,
        propGroup: String,
        onCreate: Function,
        propProject: String
    },
    components: {
        Action,
    },
    watch: {
        // propEquipment(newVal, oldVal) {
        //     this.equipment = newVal;
        // },
        propGroup(newVal, oldVal) {
            this.group = newVal;
        },
        propProject(newVal, oldVal) {
            this.project = newVal;
        }
    },
    data() {
        return {
            action: { Equipment: this.propEquipment, DeclencheurConfiguration: {}, ActionType: "", Active: true },
            availableActions: [],
            actionName: "",
            // equipment: this.propEquipment,
            group: this.propGroup,
            project: this.propProject,
            equipmentActions: [],
            shown: false
        }
    },
    methods: {
        async onCancel(e) {
        },
        async onOk(e) {
            e.preventDefault();
            if (this.$refs["actionDisplay"].validate != null) {
                var result = await this.$refs["actionDisplay"].validate();
                if (result == null) {
                    return;
                }
                result.Group = this.group;
                result.Name = this.actionName;
                result = await ProdComActionService.createAction(this.propProject, this.propEquipment, result.ActionType, result);
                if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
                else {
                    // Action has been created, we can close the wizard and refresh the configuration on the backend and on the frontend.
                    if (this.onCreate != null) this.onCreate();
                    // Reset the equipment of this wizard for future use.
                    this.action = { Equipment: "", DeclencheurConfiguration: {}, ActionType: "", Active: true };
                }
                this.$nextTick(() => {
                    this.$bvModal.hide('newAction');
                })
            }
        },
        async onShow(e) {
            this.shown = false;
            this.actionName = ""
            var result = await ProdComActionService.getActionTypes();
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else {
                this.availableActions = [];
                for (const act of result.ret) {
                    this.availableActions.push({ text: this.$t(`actions.types.${act}`), value: act })
                }
                this.action = { Equipment: this.propEquipment, DeclencheurConfiguration: {}, ActionType: this.availableActions[0].value, Active: true };
                // Get list of actions to check if name already exists
                result = await ProdComActionService.getActions(this.propEquipment, this.propProject);
                if (result.success == 'y') {
                    this.equipmentActions = result.ret.List;
                    var i = 1
                    var resultNames = this.equipmentActions.map(r => r.Name);
                    while (resultNames.findIndex(r => r === `${this.propEquipment} ${this.availableActions[0]?.text} ${i}`) != -1) {
                        i++;
                    }
                    this.actionName = `${this.propEquipment} ${this.availableActions[0]?.text} ${i}`;
                }
            }
            this.shown = true;
        },
        async onChange(e) {
            const act = this.availableActions.find(ac => ac.value === this.action.ActionType);
            if (act !== null && act !== undefined) {
                var i = 1
                var resultNames = this.equipmentActions.map(r => r.Name);
                while (resultNames.findIndex(r => r === `${this.propEquipment} ${act?.text} ${i}`) != -1) {
                    i++;
                }
                this.actionName = `${this.propEquipment} ${act?.text} ${i}`;
            }
        },
        async displayHelp() {
            this.$store.commit('displayHelp', this.$t('help.actions.'+this.action.ActionType));
            this.$store.commit('displayHelpTitle', this.$t('help.actions.'+this.action.ActionType+'.title'))
            this.$bvModal.show('bv-modal-help');
        },
    }
}
</script>